exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-crypto-inflation-tsx": () => import("./../../../src/pages/crypto-inflation.tsx" /* webpackChunkName: "component---src-pages-crypto-inflation-tsx" */),
  "component---src-pages-exchanges-comparison-tsx": () => import("./../../../src/pages/exchanges/comparison.tsx" /* webpackChunkName: "component---src-pages-exchanges-comparison-tsx" */),
  "component---src-pages-exchanges-tsx": () => import("./../../../src/pages/exchanges.tsx" /* webpackChunkName: "component---src-pages-exchanges-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-quiz-tsx": () => import("./../../../src/pages/quiz.tsx" /* webpackChunkName: "component---src-pages-quiz-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-staking-tsx": () => import("./../../../src/pages/staking.tsx" /* webpackChunkName: "component---src-pages-staking-tsx" */),
  "component---wp-coins-archive-tsx": () => import("./../../../wp/coins/archive.tsx" /* webpackChunkName: "component---wp-coins-archive-tsx" */),
  "component---wp-coins-single-country-tsx": () => import("./../../../wp/coins/single-country.tsx" /* webpackChunkName: "component---wp-coins-single-country-tsx" */),
  "component---wp-coins-single-tsx": () => import("./../../../wp/coins/single.tsx" /* webpackChunkName: "component---wp-coins-single-tsx" */),
  "component---wp-pages-single-tsx": () => import("./../../../wp/pages/single.tsx" /* webpackChunkName: "component---wp-pages-single-tsx" */),
  "component---wp-sites-archive-parent-tsx": () => import("./../../../wp/sites/archive-parent.tsx" /* webpackChunkName: "component---wp-sites-archive-parent-tsx" */),
  "component---wp-sites-archive-user-tsx": () => import("./../../../wp/sites/archive-user.tsx" /* webpackChunkName: "component---wp-sites-archive-user-tsx" */),
  "component---wp-sites-single-tsx": () => import("./../../../wp/sites/single.tsx" /* webpackChunkName: "component---wp-sites-single-tsx" */),
  "component---wp-sites-users-tsx": () => import("./../../../wp/sites/users.tsx" /* webpackChunkName: "component---wp-sites-users-tsx" */)
}

